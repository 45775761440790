<template>
  <AuthPasswordReset @reset="onReset" :showHint="false" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { AuthPasswordReset } from '@bd/components'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'PasswordReset',
  components: { AuthPasswordReset },
  setup() {
    const router = useRouter()
    const onReset = () => router.push({ path: '/password-reset-confirmation' })
    return { onReset }
  },
})
</script>

<style lang="scss" scoped></style>
